'use client';

import './quillStyles.css';
import './chakra-overrides.css';

import { ChakraTheme, extendTheme } from '@chakra-ui/react';
import { theme as baseTheme } from '@saas-ui/react';

import { colors } from './colors';
import { Breadcrumb } from './components/Breadcrumb';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { dividerTheme } from './components/divider';
import { Heading } from './components/Heading';
import { inputTheme } from './components/Input';
import { menuTheme } from './components/Menu';
import { modalTheme } from './components/Modal';
import { Table } from './components/Table';
import { tabsTheme } from './components/Tabs';
import { Text } from './components/Text';
import { textareaTheme } from './components/Textarea';

const additionalStyles: Partial<ChakraTheme> = {
  components: {
    Breadcrumb,
    Button,
    Container,
    Divider: dividerTheme,
    Heading,
    Input: inputTheme,
    Modal: modalTheme,
    Table,
    Tabs: tabsTheme,
    Text,
    Textarea: textareaTheme,
    Menu: menuTheme,
  },
  fontSizes: {
    h_2xl: '72px',
    h_lg: '48px',
    h_md: '36px',
    h_sm: '30px',
    h_xl: '60px',
    h_xs: '24px',
    lg: '18px',
    md: '16px',
    sm: '14px',
    xl: '20px',
    xs: '12px',
  },
  fonts: {
    // heading: 'Inter',
    // body: 'Inter',
  },
  lineHeights: {
    h_2xl: '90px',
    h_lg: '60px',
    h_md: '44px',
    h_sm: '38px',
    h_xl: '72px',
    h_xs: '32px',
    lg: '28px',
    md: '24px',
    sm: '20px',
    xl: '30px',
    xs: '18px',
  },
  shadows: {
    md: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },
  styles: {
    global: {
      /* Overwrite the default to keep the scrollbar always visible */
      '::-webkit-scrollbar': {
        WebkitAppearance: 'none !important',
        width: '7px !important',
        height: '8px !important',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.5) !important',
        borderRadius: '4px !important',
        boxShadow: '0 0 1px rgba(255,255,255,.5) !important',
      },
      body: {
        color: 'gray.700',
      },
      footer: {
        color: 'gray.600',
        padding: '48px 0 16px',
        textAlign: 'center',
      },
      h1: {
        fontSize: 'h_2xl',
        letterSpacing: '-2%',
        lineHeight: 'h_2xl',
      },
      h2: {
        fontSize: 'h_xl',
        letterSpacing: '-2%',
        lineHeight: 'h_xl',
      },
      h3: {
        fontSize: 'h_lg',
        letterSpacing: '-2%',
        lineHeight: 'h_lg',
      },
      h4: {
        fontSize: 'h_md',
        lineHeight: 'h_md',
      },
      'html, body': {
        minHeight: '100%',
      },
      li: {
        marginLeft: '0.5rem',
      },
      ol: {
        fontSize: 'sm',
        lineHeight: 'sm',
        listStylePosition: 'inside',
      },
      p: {
        fontSize: 'sm',
        lineHeight: 'sm',
      },
      ul: {
        fontSize: 'sm',
        lineHeight: 'sm',
        listStylePosition: 'inside',
      },
    },
  },
};

export const theme = extendTheme(
  { colors, ...additionalStyles },
  baseTheme,
) as ChakraTheme;
