import {
  defaultDataIdFromObject,
  InMemoryCacheConfig,
  StoreObject,
} from '@apollo/client';

import { LinkedProject } from '@/modules/projects/hooks/useLinkProjects';
import {
  Application,
  ApplicationElement,
  ApplicationElementInstance,
  ApplicationSection,
} from '@/types/application';

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  dataIdFromObject: (object: Readonly<StoreObject>) => {
    // LinkedProject is artificially created instance on backend, it doesn't have id field,
    // so we need to create custom cache key. Pairing of originalProjectId and linkedProjectId should be unique.
    if (object.__typename === 'LinkedProject') {
      const typedObject = object as unknown as LinkedProject;

      return `${typedObject.__typename}:${typedObject.originalProjectId}_${typedObject.linkedProjectId}`;
    }

    // ProjectUsers are users with roles and permission in project, we need to create custom cache key for them
    // As they are not unique by id, but we can use userId-projectId combo
    // Remember: user is another type, so we get only a reference to it
    if (object.__typename === 'ProjectUser') {
      const typedObject = object as unknown as Omit<ProjectUser, 'user'> & {
        user: { __ref: `User:${string}` };
      };
      const userId = typedObject.user.__ref.split(':')[1];

      return `ProjectUser:${userId}_${typedObject.projectId}`;
    }

    if (object.__typename === 'App') {
      const typedObject = object as Application;

      if (typedObject.projectId) {
        return `App:${typedObject.projectId}_${typedObject.id}`;
      }

      return defaultDataIdFromObject(typedObject);
    }

    if (object.__typename === 'AppSection') {
      const typedObject = object as unknown as ApplicationSection;

      if (typedObject.projectId) {
        return `AppSection:${typedObject.projectId}_${typedObject.id}`;
      }

      return defaultDataIdFromObject(object);
    }

    if (object.__typename === 'AppElementInstance') {
      const typedObject = object as unknown as ApplicationElementInstance;
      const prefix = typedObject.__typename;
      const suffix = `${typedObject.sectionId}_${typedObject.elementId}`;

      if (typedObject.projectId) {
        return `${prefix}:${typedObject.projectId}_${suffix}`;
      }

      // @todo: consider sortOrder + parentElementId
      return `${prefix}:${suffix}`;
    }

    if (object.__typename === 'AppElement') {
      const typedObject = object as unknown as ApplicationElement;

      if (typedObject.projectId) {
        return `AppElement:${typedObject.projectId}_${typedObject.id}`;
      }

      return defaultDataIdFromObject(object);
    }
    // @todo: consider using custom cache key for user, making it a join of both id and entityId

    return defaultDataIdFromObject(object);
  },
};
